.header{
    width: 100%;
    display: flex;
    justify-content:center;
}
.lable {
    height: 25px;
    flex:1 !important;
}
.name {
    height: 25px;
    flex:3 !important;
}