.bg {
    background-color: green;
}

.high{
    height: 40px;
    background-color: yellow;
}

.space{
    background-color: aqua;
}